import { SelectorObj, DomAppliers } from '../types'
import { getVariantSelector } from '../utils/variantsUtils'

const variablesDomSelector = (idSelector: string, variantSelector: string) => {
	if (!variantSelector) {
		return idSelector
	}

	return variantSelector.startsWith(idSelector) ? variantSelector : `${variantSelector} ${idSelector}`
}
export const variablesDomApplier: DomAppliers['variables'] = (
	__,
	idSelector,
	breakpointId,
	{ variables, variablesVariants }
) => {
	const acc: SelectorObj = {}

	const variablesInBreakpoint = variables?.[breakpointId]
	for (const variantKey in variablesInBreakpoint) {
		const variantSelector = variablesVariants ? getVariantSelector(variantKey, variablesVariants) : ''
		const cssObj = variablesInBreakpoint[variantKey]
		const domSelector = variablesDomSelector(idSelector, variantSelector)
		acc[domSelector] = Object.assign(acc[domSelector] || {}, cssObj)
	}

	return acc
}
