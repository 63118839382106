import type { BaseDataItem, ClassVariant, HoverVariant, PresetVariant } from '@wix/thunderbolt-becky-types'
import type { VariantRelationViewItem, CompVariantsViewItem } from '@wix/thunderbolt-catharsis'

const VARIANT_SELECTOR: Record<string, (variant: any) => string> = {
	Hover: (variant: HoverVariant) => `#${variant.componentId}:hover`,
	Class: (variant: ClassVariant) => `#${variant.componentId}.${variant.id}`,
	Preset: (variant: PresetVariant) => `.${variant.id}`,
	Mobile: () => '.device-mobile-optimized',
}

export const getVariantSelector = (variantId: string, variants: CompVariantsViewItem) => {
	if (!variantId) {
		return ''
	}
	const variant = variants[variantId]
	return VARIANT_SELECTOR[variant.type](variant)
}

export const isVariantRelation = <TItem>(item: BaseDataItem): item is VariantRelationViewItem<TItem> =>
	item.type === 'VariantRelation'

export const VARIANTS_SEPARATOR = '$$$'
