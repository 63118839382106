import { SelectorObj, DomAppliers } from '../types'
import { constants } from '@wix/thunderbolt-becky-root'
import { getRegularIdSelector } from '../utils/selectorsUtils'
import { getVariantSelector } from '../utils/variantsUtils'

const { PINNED_LAYER_SUFFIX } = constants

const addLayoutSelectorType = (
	compId: string,
	selector: string,
	layoutSelectorType: string = '',
	shouldOmitWrapperLayers: boolean = false
): string => {
	switch (layoutSelectorType) {
		case '::before':
			return `${selector}::before`
		case 'next-siblings':
			return `${selector} ~ *`
		case 'component-one-cell-grid':
			return `${selector}:not(.${compId}-container)`
		case 'component':
		case 'item':
			return selector
		default:
			if (shouldOmitWrapperLayers) {
				if (selector === getRegularIdSelector(compId)) {
					return `.${compId}-${layoutSelectorType}`
				}
			} else {
				return `${selector} .${compId}-${layoutSelectorType}`
			}
			return selector
	}
}

const responsiveLayoutDomSelector = (
	compId: string,
	idSelector: string,
	variantSelector: string,
	selector: string,
	shouldOmitWrapperLayers: boolean
): string => {
	const hasVariant = variantSelector
	const sameComponentVariant = variantSelector && variantSelector.startsWith(idSelector)
	const compSelector = addLayoutSelectorType(
		compId,
		sameComponentVariant ? variantSelector : idSelector,
		selector,
		shouldOmitWrapperLayers
	)
	const domSelector = hasVariant && !sameComponentVariant ? `${variantSelector} ${compSelector}` : compSelector
	return domSelector
}

const pinnedLayerDomSelector = (idSelector: string): string => `${idSelector}${PINNED_LAYER_SUFFIX}`

export const responsiveLayoutDomApplier: DomAppliers['responsiveLayout'] = (
	compId,
	idSelector,
	breakpointId,
	{ responsiveLayout, pinnedLayer, layoutVariants }
) => {
	const acc: SelectorObj = {}

	const responsiveLayoutInBreakpoint = responsiveLayout?.css[breakpointId]
	for (const variantKey in responsiveLayoutInBreakpoint) {
		const variantSelector = layoutVariants ? getVariantSelector(variantKey, layoutVariants) : ''
		const selectorObj = responsiveLayoutInBreakpoint[variantKey]
		for (const selector in selectorObj) {
			const domSelector = responsiveLayoutDomSelector(
				compId,
				idSelector,
				variantSelector,
				selector,
				!!responsiveLayout?.shouldOmitWrapperLayers
			)
			acc[domSelector] = Object.assign(acc[domSelector] || {}, selectorObj[selector])
		}
	}

	const pinnedLayerInBreakpoint = pinnedLayer?.[breakpointId]
	for (const variantKey in pinnedLayerInBreakpoint) {
		const selectorObj = pinnedLayerInBreakpoint[variantKey]
		const domSelector = pinnedLayerDomSelector(idSelector)
		for (const selector in selectorObj) {
			acc[domSelector] = selectorObj[selector]
		}
	}

	return acc
}
